import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { Card, CardContent, Typography } from '@material-ui/core';
import Layout from '../components/layout'

const Tag = ({ pageContext }) => {
  const { posts, tagName } = pageContext
  return (
    <Layout>
      <Helmet title={tagName} />
      <div className="wrapper">
        <h1>{`${tagName}`}</h1>
        <div>
          {posts.map((post, index) => {
            const path = '/blog/' + post.slug;
            return (
              <Card key={index} variant='outlined' style={{ marginBottom: 10 }}>
                <Link to={path}>
                  <CardContent>
                    <Typography>{post.publishDate}</Typography>
                    <Typography>{post.title}</Typography>
                  </CardContent>
                </Link>
              </Card>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Tag
